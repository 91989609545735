import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/content/graphql-error-list";
import Work from "../components/layout/Work/Work";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query WorkTemplateQuery($id: String!) {
    work: sanityWork(id: { eq: $id }) {
      id
      publishedAt
      title
      _rawExcerpt
      whatwedid {
        _id
        title
      }
      clients {
        _id
        name
        _rawClientFullTitle
        url
      }

      slug {
        current
      }
      _rawBody
      imagesGallery {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      workSection {
        sectionTitle
        _rawSectionBody
      }
    }
  }
`;

const WorkTemplate = ({ data, errors, location, pageContext }) => {
  const work = data && data.work;
  return (
    <Layout location={location}>
      {errors && <SEO title="GraphQL Error" />}
      {work && <SEO title={work.title || "Untitled"} />}

      {errors && <GraphQLErrorList errors={errors} />}
      {work && <Work {...work} pageContext={pageContext} />}
    </Layout>
  );
};

export default WorkTemplate;
