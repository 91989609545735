import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import classNames from "classnames";
import { buildImageObj, mapEdgesToNodes } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
import BlockContent from "../../content/block-content";
import BlockText from "../../content/block-text";
import { Typography } from "../../common/Typography/Typography";
import styles from "./Work.module.scss";
import { Title } from "../../common/Title/Title";
import { Slider } from "../../common/Slider";

const Work = ({
  _rawBody,
  title,
  _rawExcerpt,
  whatwedid,
  clients,
  mainImage,
  imagesGallery,
  workSection,
  publishedAt,
  pageContext
}) => {
  const { next, prev } = pageContext;

  return (
    <article className={styles.root}>
      <div className={styles.workInner}>
        <div className={styles.whatWeDidSection}>
          {whatwedid && whatwedid.length > 0 && (
            <div className={styles.whatWeDid}>
              <h2>Methods</h2>
              <div className={styles.methods}>
                {whatwedid.map((what, index) => (
                  <div key={`what-${index}`}>
                    <div>{what.title}</div>
                    {index + 1 !== whatwedid.length && <div>–</div>}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.workMainSection}>
          <Title titleSize="heading">{title}</Title>
          <div className={styles.subtitle}>
            {_rawExcerpt && <BlockText blocks={_rawExcerpt || []} />}
          </div>

          {clients && clients.length > 0 && (
            <div className={classNames(styles.client, styles.sectionSpacer)}>
              <h2 className={styles.sectionTitle}>{clients.length > 1 ? "Clients" : "Client"}</h2>

              {clients.map(client => (
                <div key={`client-${client._id}`}>
                  <div>
                    {client.url ? (
                      <a href={client.url} target="_blank">
                        {client.name}
                      </a>
                    ) : (
                      client.name
                    )}
                  </div>
                  <div>
                    {client._rawClientFullTitle && (
                      <BlockText blocks={client._rawClientFullTitle || []} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {imagesGallery && (
            <Slider
              settings={{
                controls: true
                // nav: false,
                // mouseDrag: true,
                // edgePadding: 16,
                // gutter: 100,
                // center: true
              }}
            >
              {imagesGallery.map(
                (image, index) =>
                  image &&
                  image.asset && (
                    <div
                      className={classNames(styles.mainImage, styles.sectionSpacer)}
                      key={`galleryimage-${index}`}
                    >
                      <Img
                        key={`slider-${index}`}
                        fluid={{ ...image.asset.fluid, aspectRatio: 4 / 3 }}
                        alt={image.alt}
                        className={styles.slideshowImage}
                      />
                    </div>
                  )
              )}
            </Slider>
          )}

          <div className={classNames(styles.mainContent, styles.sectionSpacer, styles.bigLeading)}>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>

          <div>
            {workSection &&
              workSection.map(section => (
                <div
                  className={classNames(styles.workSection, styles.sectionSpacer)}
                  key={`${section.sectionTitle}-${section._id}`}
                >
                  <h2 className={styles.sectionTitle}>
                    {section.sectionTitle && section.sectionTitle}
                  </h2>
                  <div className={`${styles.mainContent} ${styles.bigLeading}`}>
                    {section._rawSectionBody && (
                      <BlockContent
                        className={styles.bigLeading}
                        blocks={section._rawSectionBody || []}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* <div className={styles.empty}></div> */}
      </div>
      <div className={styles.prevnext}>
        {prev && (
          <Link to={`/work/${prev.slug.current}`} className={styles.prev}>
            {`< Previous project`}
          </Link>
        )}
        {next && (
          <Link to={`/work/${next.slug.current}`} className={styles.next}>
            {` Next project >`}
          </Link>
        )}
      </div>
    </article>
  );
};

export default Work;
