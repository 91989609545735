import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
// import useMeasure from 'react-use-measure';
import useMeasure from "use-measure";
import classNames from "classnames";
// import TinySlider from 'tiny-slider-react';
import styles from "./Slider.module.scss";

const TinySlider = loadable(() => import("tiny-slider-react"), { ssr: false });

const Slider = ({ children, settings = {}, className, onRef, ...props }) => {
  const mergedSettings = useMemo(() => {
    return {
      lazyload: true,
      nav: false,
      mouseDrag: true,
      ...settings
    };
  }, [settings]);

  const wrapperRef = useRef();
  const measurement = useMeasure(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      // style={{ '--slider-height': `${measurement.height}px` }}
      className={classNames(styles.sliderWrapper, className)}
    >
      <TinySlider ref={ref => onRef && onRef(ref)} settings={mergedSettings} {...props}>
        {children}
      </TinySlider>
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
  className: PropTypes.string,
  onRef: PropTypes.func
};

export { Slider };
